/*------------------------------------------------------------------
Project:    Nairo Personal Portfolio React Template
Version:    1.0
Primary use:  ib-themes

[Table of contents]

1. variable
2. mixin
3. base
4. color
5. header
6. footer
7. blog
8. portfolio
9. style
10. 404
11. preview
12. animation
13. bootstrap
14. themefy

-------------------------------------------------------------------*/
@import "bootstrap";
@import "et-line-font";
@import "main/variable";
@import "main/mixin";
@import "main/base";
@import "main/button";
@import "main/color";
@import "main/header";
@import "main/footer";
@import "main/blog";
@import "main/portfolio";
@import "main/style";
@import "main/404";
@import "main/preview";
@import "main/animation";
@import "main/slick";
@import "main/modal";
@import "main/rtl";
@import "main/custom-theme-dark";

.loadingItems span {
  background-color: $px-theme;
}

.loadingItems {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.awardIconImage {
  line-height: 60px;
  font-size: 40px;
  text-align: center;
  color: $px-theme;
}

.img-in {
  border: none !important;
}

.portfolio-info * {
  color: $px-theme !important;
}

@include down-sm {
  #home .type-box {
    margin-right: 15%;
  }

  .home-banner .hb-info div:last-of-type {
    display: flex;
    flex-direction: column;
  }

  .home-banner .hb-info div:last-of-type a:nth-child(3) {
    margin: 0;
  }

}

@include up-md {
  .home-banner .hb-info {
    display: flex;
    width: 90%;
  }
}

@include down-md {
  .home-banner .hb-info div:last-of-type a:first-of-type {
    display: none;
  }

  .home-banner .hb-info div:last-of-type a:nth-child(2) {
    margin: 0;
  }
}

.home-banner .hb-info div:first-of-type {
  padding-right: 5%;
}
