//@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

//@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$px-theme: #e7e7e7 !default;
$px-dark: #eaeaea !default;
$px-white: #a10031 !default;
$px-black: #000 !default;

$px-bg-1: #10101a !default;
$px-bg-2: #0e0e17 !default;
$px-bg-3: #0c0c14 !default;
$px-gray: #8d0028 !default;

$px-body: rgba(255, 255, 255, 0.8) !default;
$px-body-light: #cccbcb !default;
$px-font-size: 15px !default;
$px-line-height: 1.7 !default;
$px-font: "Rubik", monospac !default;
$px-font-alt: "Rubik", serif !default;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;


/* rubik-300 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-600 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src: url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-600.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-800 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  src: url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-800.woff') format('woff'), /* Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-800.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-900 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../google-fonts/rubik-v21-latin/rubik-v21-latin-900.svg#Rubik') format('svg'); /* Legacy iOS */
}